import "core-js/modules/es.array.push.js";
import * as local from '@/libs/local';
//import HelloWorld from '@/components/HelloWorld.vue';
import { Toast } from 'vant';
import Vue from 'vue';
import { Swipe, SwipeItem } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Grid, GridItem } from 'vant';
import * as api from '@/api';
import { ref } from 'vue';
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { NoticeBar } from 'vant';
import { NavBar } from 'vant';
import { Picker } from 'vant';
import { Empty } from 'vant';
import { BackTop } from 'vant';
import { List } from 'vant';
import { Slider } from 'vant';
import 'vant/es/empty/style';
import Header from '../lyout/header.vue';
import Footer from '../lyout/footer.vue';
import Ce from '../lyout/ce.vue';
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Ce
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    }
  },
  data() {
    return {
      banner: [],
      goods: [],
      lang_list: [],
      select_lang: {},
      lang: {},
      cate: [],
      user: {},
      news: [],
      sort_line: 0,
      loading: false,
      finished: false,
      is_jia: false,
      page: 1,
      value: [0, 100000],
      shuxing: [],
      select_v: 0
    };
  },
  created: function () {
    let _this = this;
    // _this.get_imdex_data();

    this.onload();
  },
  methods: {
    onClickLeft() {
      api.chat(0);
    },
    back() {
      window.history.back();
    },
    sort: function (type) {
      this.sort_line = type;
      local.saveInfo('sort_line1', type);
    },
    onChange: function () {
      this.onload(true);
    },
    onload: function (name = false) {
      if (this.is_jia) {
        return false;
      }
      this.is_jia = true;
      if (name) {
        this.goods = [];
        this.page = 1;
        this.finished = false;
        this.is_has = 1;
      }
      api.all('/api/index/news_detail', {
        page: this.page,
        id: this.$route.query.id,
        price: this.value,
        sort: this.select_v
      }, (err, data) => {
        if (!err && data.code === 1) {
          this.goods = data.data.goods;
          this.lang_list = data.lang_list;
          this.select_lang = data.select_lang;
          this.lang = data.data.lang;
          this.cate = data.data.cate;
          this.user = data.data.user;
        } else {
          //console.log(data);
        }
      });
    },
    detail: function (id1) {
      let _this = this;
      _this.$router.push({
        name: 'detail',
        query: {
          id: id1
        }
      });
      this.$nextTick(() => {
        window.scrollTo(0, 0); // 滚动到顶部
      });
    },
    shou: function (id = 0) {
      api.shou(id);
    }
  }
};